export default [
  {
    key: 'imageId',
    initKey: 'image',
    label: 'field.image',
    type: 'async-single-image',
    path: 'product-category',
    rules: '',
    pw: 200,
    ph: 200,
    fullWidth: false,
    cols: 12,
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'color',
    label: 'field.color',
    rules: 'required|max:7',
    type: 'color-picker',
    cols: 'auto',
  },
  {
    skip: true,
    hide: true,
    cols: 12,
  },
  {
    key: 'isEnable',
    label: 'field.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
